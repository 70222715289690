import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { Link } from '@reach/router';
import Button from '@material-ui/core/Button';
import Lock from './../../assets/images/lock.png';
import './Authorize.scss';

const ModalAuthorize = () => {
	const [timeLeft, setTimeLeft] = useState(3);
	const location = useLocation();

	useEffect(() => {
		// const timer = setInterval(() => {
		//     if (timeLeft === 1) {
		//         clearInterval(timer);
		//         localStorage.setItem('ru', location.pathname);
		//         window.location.href = `${process.env.REACT_APP_API_URL}/connect/microsoft`;
		//     } else setTimeLeft(prevTime => prevTime - 1);
		// }, 1000);
		// return () => clearInterval(timer);
	}, [timeLeft, location.pathname]);

	return (
		<div className="authorize">
			<div className="lockImageContainer">
				<img src={Lock} alt="Lock Icon" className="lockIcon" />
			</div>

			<span>Access Restricted</span>
			<span>Please login to access this content</span>
			{/* <a href={`${process.env.REACT_APP_API_URL}/connect/microsoft`}>Tap To Login</a> */}
			<Button
				variant="contained"
				className="button"
				href={`${process.env.REACT_APP_API_URL}/connect/microsoft`}
			>
				Login
			</Button>
		</div>
	);
};

export default ModalAuthorize;
