import React, { useEffect } from 'react';
import Card from '@material-ui/core/Card';
import { Link } from '@reach/router';
import { CardActionArea, CardMedia } from '@material-ui/core';
import { getMonthName } from '../../utils/date';
import { getCdnUrl } from '../../utils/string';
import { fireEvent } from '../../utils/tracking';
import { connect } from 'react-redux';
import { fetchCreative } from './../../redux/creative/CreativeActions';
import { openDialogModal } from './../../redux/creative/CreativeActions';
import './CreativeCard.scss';
import LoaderImage from './../../assets/images/sample.gif';
import LockIcon from './../../assets/images/lockicon.png';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ImageLoader from './../ImageLoader';
const CreativeCard = ({ creative, fetchCreative, openModalBox }) => {
	const creativeDate = new Date(creative.campaign_created_at);
	const formattedDate = `${getMonthName(creativeDate.getMonth())} ${creativeDate.getFullYear()}`;
	const isLoggedInn = localStorage.getItem('jwt');
	const email = localStorage.getItem('email');
	const openCreativeModal = id => {
		openModalBox(id);
		fireEvent('Gallery Page', 'Click', `${id.title} Clicked`);
		fireEvent('Gallery Page', 'Click', `${id.title} creative modal opened`);
	};
	const [loaded, setLoaded] = React.useState(false);

	function handleImageLoad() {
		setLoaded(true);
	}

	return (
		<div className="creativeCard">
			{/* <Link
				to={''}
				to={`/creative/${creative.slug}`}
				onClick={() => {
					fireEvent('Home Page', 'Click', `${creative.title} Clicked`);
					openCreativeModal(creative);
				}}
			> */}
			<Card
				className="creativeCardWrapper"
				title={creative.title}
				onClick={() => {
					openCreativeModal(creative);
				}}
			>
				{creative.post_sales ? (
					<></>
				) : (
					<>
						{!isLoggedInn ? (
							<>
								<div className="bulbIcon">
									{/* <LockOutlinedIcon
									className="bulbImg"
									fontSize="large"
									style={{ color: '#ffffff', background: 'rgb(107 98 98 / 59%)' }}
								/> */}

									{/* <img src={LockIcon} alt="mock" className="bulbImg" /> */}
								</div>
							</>
						) : (
							<></>
						)}
					</>
				)}
				<CardActionArea className="creativeCardActionArea">
					<div className="creativeImageCtn">
						{/* <CardMedia
							component="img"
							className="creativeImage"
							image={creative.thumbnail ? getCdnUrl(creative.thumbnail.url) : ''}
						/> */}

						<div style={{ visibility: loaded ? 'visible' : 'hidden' }}>
							<img
								src={creative.thumbnail ? getCdnUrl(creative.thumbnail.url) : ''}
								onLoad={handleImageLoad}
								className="creativeImage"
							/>
						</div>
						<div style={{ visibility: loaded ? 'hidden' : 'visible' }} className="imageLoaderContainer">
							<div>
								{/* <img src={LoaderImage} alt="mock" className="bulbImg" /> */}
								<ImageLoader />
							</div>
						</div>
					</div>
					<div className="cardContent">
						<div className="cardViewBtn">View</div>
						<div className="cardTitle">{creative.title}</div>
						<div className="cardRegionDate">
							{creative.region ? `${creative.region.name}, ${formattedDate}` : `${formattedDate}`}
						</div>
					</div>
				</CardActionArea>
			</Card>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		fetchCreative: id => dispatch(fetchCreative(id)),
		openModalBox: id => dispatch(openDialogModal(id)),
	};
};
export default connect(null, mapDispatchToProps)(CreativeCard);
