import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '@reach/router';
import './LoginRedirect.scss';

const LoginRedirect = () => {
	const [text, setText] = useState('Redirecting...');
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const getUserDetails = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/auth/microsoft/callback${location.search}`,
				);
				if (response.status !== 200)
					throw new Error(`Couldn't complete login. Status ${response.status}`);
				const data = await response.json();
				localStorage.setItem('jwt', data.jwt);
				localStorage.setItem('email', data.user.username);

				setTimeout(() => {
					const redirectionUrl = localStorage.getItem('ru') || '/';
					localStorage.removeItem('ru');
					navigate(redirectionUrl, { replace: false });
				}, 1000);
			} catch (error) {
				setText(error.message);
			}
		};
		getUserDetails();
	}, [location.search, navigate]);

	return <div className="loginRedirect">{text}</div>;
};

export default LoginRedirect;
