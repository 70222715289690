import React, { useEffect } from 'react';
import PageTitle from '../components/PageTitle';
import Header from '../components/Header';
import Loader from '../components/Loader';
import { connect } from 'react-redux';
import Error from '../components/Error';
import AdFormatContainer from '../components/AdFormat/AdFormatContainer';
import { fetchFunctionality } from '../redux/functionality/FunctionalityActions';
import CreativeModal from '../components/Creative/CreativeModal';
const AdFormatDetails = ({
	functionalityId,
	fetchFunctionality,
	error,
	loading,
	selectedFunctionality,
}) => {
	useEffect(() => {
		fetchFunctionality(functionalityId);
	}, [fetchFunctionality, functionalityId]);

	return error ? (
		<>
			{error}
			<PageTitle pageName={''} />
			<Header button="back" />
			<Error error={error} />
		</>
	) : loading ? (
		<>
			<Loader open={loading} />
			<PageTitle pageName={''} />
			<Header button="back" />
		</>
	) : (
		<>
			<Loader open={loading} />
			<CreativeModal />
			<PageTitle />

			<Header button="back" />
			<AdFormatContainer adFormatDetails={selectedFunctionality} />
		</>
	);
};

const mapStateToProps = state => {
	return {
		loading: state.functionalityReducer.loading,
		selectedFunctionality: state.functionalityReducer.selectedFunctionality,
		error: state.functionalityReducer.error,
	};
};
export default connect(mapStateToProps, { fetchFunctionality })(AdFormatDetails);
