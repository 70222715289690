import React, { useRef, useCallback, useEffect } from 'react';
import CreativeCard from './CreativeCard';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation } from '@reach/router';
import { connect } from 'react-redux';
import {
	fetchCreatives,
	resetCreatives,
	fetchFilterCreatives,
} from '../../redux/creative/CreativeActions';
import { createFilterQuery, extractFilters, doesFilterExist } from '../../utils/filterDemo';
import './CreativeList.scss';
import ImageLoader from './../ImageLoader';

const options = {
	root: null,
	rootMargin: '0px',
	threshold: 0,
};

const CreativeList = ({
	creatives,
	isCreativeLeft,
	fetchCreatives,
	resetCreatives,
	backupCreatives,
	fetchFilterCreatives,
	creativeCount,
	searchResults,
}) => {
	const creativesLoader = useRef(null);
	const location = useLocation();
	useEffect(() => {
		resetCreatives();
	}, [resetCreatives, location.search]);
	useEffect(() => {
		// action here
		//console.log("loaded", backupCreatives.length);
		//fetchFilterCreatives(location.search);
		//if(backupCreatives.length)
	}, [backupCreatives]);
	const loadMoreCreatives = useCallback(
		entries => {
			const target = entries[0];
			if (target.isIntersecting && isCreativeLeft && backupCreatives.length > 0) {
				const filters = doesFilterExist(location.search);

				//if (filters.doesFilterExist == true && backupCreatives.length > 0) {
				fetchFilterCreatives(location.search);
				//}
				// if (filters.doesFilterExist == false) {
				// 	fetchCreatives();
				// }
			}
		},
		[isCreativeLeft, fetchFilterCreatives, location.search, backupCreatives],
	);

	useEffect(() => {
		const observer = new IntersectionObserver(loadMoreCreatives, options);
		if (creativesLoader.current) {
			const target = creativesLoader.current;
			observer.observe(target);
		}
		return () => {
			observer.disconnect();
		};
	}, [loadMoreCreatives]);
	//console.log('isCreativeLeft', isCreativeLeft);
	//console.log(searchResults);
	return !isCreativeLeft ? (
		!creatives.length ? (
			<div className="emptyCreativeList">
				No results found!
				<br />
				Try removing filters or check mockups to see more results
			</div>
		) : (
			<>
				<div className="creativeList">
					{creatives.map(creative => (
						<CreativeCard creative={creative} key={creative.id} />
					))}
				</div>
			</>
		)
	) : (
		<>
			<div className="creativeList">
				{creatives.map(creative => (
					<CreativeCard creative={creative} key={creative.id} />
				))}
			</div>
			<div
				ref={creativesLoader}
				className={creatives.length ? 'creativesLoader' : 'creativesLoader invisible'}
			>
				<CircularProgress />
			</div>
		</>
	);
};

const mapStateToProps = state => {
	return {
		creatives: state.creativeReducer.creatives,
		isCreativeLeft: state.creativeReducer.isCreativeLeft,
		backupCreatives: state.creativeReducer.backupCreatives,
		creativeCount: state.creativeReducer.creativeCount,
		searchInput: state.creativeReducer.searchInput,
		searchResults: state.searchReducer.searchResults,
	};
};

export default connect(mapStateToProps, { fetchCreatives, resetCreatives, fetchFilterCreatives })(
	CreativeList,
);
