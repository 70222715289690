import React, { useEffect } from 'react';
import Loader from '../components/Loader';
import PageTitle from '../components/PageTitle';
import Header from '../components/Header';
import HomeBanner from '../components/Home/HomeBanner';
import Error from '../components/Error';
import Cards from '../components/Home/Cards';
import AwardWinningCreatives from '../components/Home/AwardWinningCreatives';
import CubeIcon from '../components/CubeIcon';
import { fetchAllCreativesFromHome } from '../redux/creative/CreativeActions';
import { connect } from 'react-redux';
import CreativeModal from '../components/Creative/CreativeModal';
const Home = ({ fetchAllCreativesFromHome, backupCreatives }) => {
	useEffect(() => {
		if (backupCreatives.length == 0) {
			fetchAllCreativesFromHome();
		}
	}, [fetchAllCreativesFromHome, backupCreatives]);

	return (
		<>
			<CreativeModal />
			<PageTitle />
			<Header button="menu" />
			<HomeBanner />
			{/* <CubeIcon /> */}
			<Cards />
			<CubeIcon />
			{/* <AwardWinningCreatives details={selectedCreatives} /> */}
			<AwardWinningCreatives />
		</>
	);
};
const mapStateToProps = state => {
	return {
		loading: state.homeReducer.loading,
		backupCreatives: state.creativeReducer.backupCreatives,
	};
};

export default connect(mapStateToProps, { fetchAllCreativesFromHome })(Home);
