import axios from 'axios';
import {
	FETCH_REQUEST,
	FETCH_FAILURE,
	FETCH_AWARD_WINNING_CREATIVE_SUCCESS,
	HIDE_LOADER,
} from './HomeActionTypes';
export const fetchRequest = () => {
	return {
		type: FETCH_REQUEST,
	};
};
export const fetchFailure = error => {
	return {
		type: FETCH_FAILURE,
		payload: error,
	};
};
export const fetchAwardWinningCreativeSuccess = creative => {
	return {
		type: FETCH_AWARD_WINNING_CREATIVE_SUCCESS,
		payload: creative,
	};
};

export const fetchAwardWinningCreatives = () => dispatch => {
	dispatch(fetchRequest());
	axios({
		method: 'post',
		url: `${process.env.REACT_APP_API_URL}/graphql`,
		data: {
			// query: `query{
			// 		creatives(where:{specialtags:{name_in:["Award Winning"]},post_sales:true},limit:5,sort: "createdAt:desc"){
			// 			id,
			// 			title,
			// 			thumbnail{
			// 					url
			// 				},
			// 			slug,
			// 			campaign_created_at,
			// 				region{
			// 					name
			// 				}

			// 		}
			// 	}`,
			query: `query{
						specialtags(where:{name_in:["Award Winning"]}){
							featured(where:{post_sales:true},limit:5,sort:"createdAt:desc"){
					  		id,
								title,
								thumbnail{
										url
									},
								slug,
								campaign_created_at,
									region{
										name
					  }
					}
						  }
			}`,
		},
	})
		.then(response => {
			return dispatch(fetchAwardWinningCreativeSuccess(response.data.data.specialtags[0]));
		})
		.catch(err => dispatch(fetchFailure(err)));
};
export const hideLoader = () => dispatch => {
	dispatch(hideLoader1());
};
export const hideLoader1 = () => {
	return {
		type: HIDE_LOADER,
	};
};
