import React, { useState } from 'react';
import Icon from '@material-ui/core/Icon';
import copyClipboard from '../../assets/images/clipboard.svg';
import AddToHomeScreenRoundedIcon from '@material-ui/icons/AddToHomeScreenRounded';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import { useNavigate, useLocation } from '@reach/router';
import CopyToClipBoard from './../../assets/images/copyToClipBoard.png';
import './CreativePreviewLink.scss';
const CreativePreviewLink = ({ selectedCreative }) => {
	const [copySuccess, setCopySuccess] = useState(false);
	const location = useLocation();
	const copyToClipboard = id => {
		navigator.clipboard.writeText(id);
		setCopySuccess(value => !value);
		//openModalBox();
	};
	return (
		<div className={copySuccess ? 'previewLinkContainer linkCopied' : 'previewLinkContainer'}>
			<img
				src={CopyToClipBoard}
				onClick={() => copyToClipboard(location.origin + '/creative/' + selectedCreative.slug)}
			/>
		</div>
	);
};
export default CreativePreviewLink;
