import React from 'react';
import VerticalDetails from './VerticalDetails';
import VerticalExamples from './VerticalExamples';

const VerticalContainer = ({ details }) => {
	return (
		<div className="creative-format-container">
			<VerticalDetails details={details} />
			<VerticalExamples
				examples={details.featured}
				creatives={details.creatives}
				verticalType={details.name}
			/>
		</div>
	);
};
export default VerticalContainer;
