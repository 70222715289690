import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import CreativeActionsSection from './CreativeActionsSection';
import { Carousel } from 'react-responsive-carousel';
import { connect } from 'react-redux';
import { getMonthName } from '../../utils/date';
import { getCdnUrl, getFileType } from '../../utils/string';
import './CreativeInfo.scss';
import CreativePreviewLink from './CreativePreviewLink';
import { readMore } from './../../redux/creative/CreativeActions';
const CreativeInfo = ({ selectedCreative = {}, showMore, readMore }) => {
	const campaignCreatedDate = selectedCreative.campaign_created_at
		? new Date(selectedCreative.campaign_created_at)
		: '';

	let desc = selectedCreative.description;
	let video = null;
	const images = [];

	const toggleShowMore = open => {
		readMore();
	};

	if ((JSON.stringify(selectedCreative) === '{}') != true) {
		if (selectedCreative.attachment.length > 0) {
			selectedCreative.attachment.map(item => {
				const fileType = getFileType(item.url);

				if (fileType == 'video') {
					video = item.url;
				}
				if (fileType == 'image') {
					images.push(item.url);
				}
			});
		}
	}

	if (desc != '' && desc != undefined) {
		const containsString = selectedCreative.description.search('\n');

		if (containsString != -1) {
			//desc = selectedCreative.description.replace('\n', '\b \n\n');
			desc = desc.replace(/(?:\r\n|\r|\n)/g, '\n \n');
		}
	}

	return Object.keys(selectedCreative).length ? (
		<div className="creativeInfo">
			<div className="creativeTitle">{selectedCreative.title}</div>
			<div className="creativeDate">
				<div>
					{campaignCreatedDate
						? `${getMonthName(campaignCreatedDate.getMonth())} ${campaignCreatedDate.getFullYear()},`
						: ''}
				</div>
				<div className="creativeCountry">
					{selectedCreative.country ? `${selectedCreative.country.name}` : ''}
				</div>
				{selectedCreative.post_sales ? (
					<div></div>
				) : (
					<div className="creativeMockupContainer">
						<div className="mockup">MOCKUP</div>
					</div>
				)}
			</div>
			{/* <CreativePreviewLink selectedCreative={selectedCreative} /> */}

			<div className="wraaperContainer">
				<CreativeActionsSection selectedCreative={selectedCreative} />
			</div>
			<div className="upperWrapper">
				<div className="creativeDescription">
					<ReactMarkdown>{desc}</ReactMarkdown>
					{selectedCreative.attachment.length > 0 ? (
						<div
							className="readMoreIcon"
							style={showMore ? { display: 'none' } : { display: 'block' }}
							onClick={() => {
								toggleShowMore();
							}}
						>
							Read More ...
						</div>
					) : (
						<></>
					)}

					<div
						className="attachmentCarouselSection"
						style={showMore ? { display: 'block' } : { display: 'none' }}
					>
						{video ? (
							<div className="attachmentAttachment">
								<video
									muted
									autoPlay
									playsInline
									preload="auto"
									loop
									controls
									src={getCdnUrl(video)}
								></video>
							</div>
						) : (
							<div></div>
						)}
						{images.length > 0 ? (
							<div className="attachmentCarouselCtn">
								<Carousel
									showArrows={true}
									showThumbs={false}
									showIndicators={false}
									infiniteLoop
									interval={2000}
									className="attachmentCarousel"
									statusFormatter={(current, total) => ``}
								>
									{images.map((item, index) => {
										return (
											<div>
												<img src={item} alt="" width="100%" key={index} />
											</div>
										);
									})}
								</Carousel>
							</div>
						) : (
							<div></div>
						)}
					</div>
				</div>
			</div>
		</div>
	) : null;
};
const mapDispatchToProps = dispatch => {
	return {
		readMore: () => dispatch(readMore()),
	};
};
const mapStateToProps = state => {
	return {
		selectedCreative: state.creativeReducer.selectedCreative,
		showMore: state.creativeReducer.showMore,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreativeInfo);
