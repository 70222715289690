import React, { useEffect } from 'react';
// import SearchBar from './SearchBar';
import './AwardWinningCreatives.scss';
import Loader from '../Loader';
import Error from '../Error';
import AwardWinningList from './AwardWinningList';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { fetchAwardWinningCreatives, hideLoader } from '../../redux/home/HomeActions';
const AwardWinningCreatives = ({
	loading,
	error,
	fetchAwardWinningCreatives,
	selectedCreatives,
	hideLoader,
}) => {
	useEffect(() => {
		if (!Object.keys(selectedCreatives).length) {
			fetchAwardWinningCreatives();
		} else {
			hideLoader();
		}
	}, [loading, error, fetchAwardWinningCreatives, selectedCreatives, hideLoader]);
	return error ? (
		<Error error={error} />
	) : loading ? (
		<>
			<Loader open={loading} />
		</>
	) : (
		<>
			<div className="awardWinningCreatives">
				<div className="heading">AWARD WINNING CREATIVES</div>
				<div className="description">
					Explore InMobi's award winning ads that spark exploration and elevate brand experience!
				</div>
				<AwardWinningList />
				<Button
					variant="contained"
					className="button"
					href="/gallery?specialtags=Award%20Winning&postsales"
				>
					See More
				</Button>
			</div>
		</>
	);
};
const mapStateToProps = state => {
	return {
		loading: state.homeReducer.loading,
		selectedCreatives: state.homeReducer.selectedCreatives,
	};
};
export default connect(mapStateToProps, { fetchAwardWinningCreatives, hideLoader })(
	AwardWinningCreatives,
);
