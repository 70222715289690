import axios from 'axios';
import {
	FETCH_REQUEST,
	FETCH_FAILURE,
	RESET_CREATIVES,
	FETCH_CREATIVES_SUCCESS,
	FETCH_FILTERS_SUCCESS,
	FETCH_CREATIVE_SUCCESS,
	AUTHORIZATION_FAILURE,
	SET_SELECTED_FILTERS,
	OPEN_DIALOG_MODAL,
	CLOSE_DIALOG_MODAL,
	SET_SPECIAL_TAGS,
	FETCH_ALL_CREATIVE_SUCCESS,
	SHOW_MORE,
	FETCH_CREATIVES_COUNT,
	FETCH_CREATIVES_FROM_HOME,
} from './CreativeActionTypes';
import {
	extractFilters,
	filterData,
	specialTags,
	pagination,
	fetchFiltersDetails,
} from '../../utils/filterDemo';

export const fetchRequest = () => {
	return {
		type: FETCH_REQUEST,
	};
};

export const fetchFailure = error => {
	return {
		type: FETCH_FAILURE,
		payload: error,
	};
};

export const resetCreatives = () => {
	return {
		type: RESET_CREATIVES,
	};
};
export const openModal = () => {
	return {
		type: OPEN_DIALOG_MODAL,
	};
};
export const closeModal = () => {
	return {
		type: CLOSE_DIALOG_MODAL,
	};
};
export const showMore = () => {
	return {
		type: SHOW_MORE,
	};
};
export const fetchCreativesSuccess = creatives => {
	return {
		type: FETCH_CREATIVES_SUCCESS,
		payload: creatives,
	};
};

export const creativeCount = count => {
	return {
		type: FETCH_CREATIVES_COUNT,
		payload: count,
	};
};
export const fetchFiltersSuccess = filters => {
	return {
		type: FETCH_FILTERS_SUCCESS,
		payload: filters,
	};
};

export const fetchCreativeSuccess = creative => {
	return {
		type: FETCH_CREATIVE_SUCCESS,
		payload: creative,
	};
};
export const fetchAllCreativeSuccess = creative => {
	return {
		type: FETCH_ALL_CREATIVE_SUCCESS,
		payload: creative,
	};
};
export const fetchAllCreativeFromHomeSuccess = creatives => {
	return {
		type: FETCH_CREATIVES_FROM_HOME,
		payload: creatives,
	};
};
export const setSpecialTags = tags => {
	return {
		type: SET_SPECIAL_TAGS,
		payload: tags,
	};
};
export const setSelectedFilters = filters => {
	return {
		type: SET_SELECTED_FILTERS,
		payload: filters,
	};
};

export const authorizationFailure = () => {
	return {
		type: AUTHORIZATION_FAILURE,
	};
};

// export const fetchCreatives = queryString => (dispatch, getState) => {
// 	const startIndex = getState().creativeReducer.creativesStartIndex;
// 	const limit = getState().creativeReducer.creativeRequestSize;
// 	if (startIndex === 0) dispatch(fetchRequest());
// 	const filters = extractFilters(queryString);
// 	dispatch(setSelectedFilters(filters));
// 	const filterQuery = createFilterQuery(
// 		getState().creativeReducer.selectedFilters,
// 		getState().creativeReducer.searchInput,
// 	);
// 	//console.log(filterQuery);
// 	const filter = filterQuery;
// 	axios({
// 		method: 'get',
// 		url:
// 			`${process.env.REACT_APP_API_URL}/creatives?` +
// 			filter +
// 			`&_limit=` +
// 			limit +
// 			`&_start=` +
// 			startIndex +
// 			`&_sort=createdAt:DESC`,
// 	})
// 		.then(response => {
// 			console.log(response.data);
// 			return dispatch(fetchCreativesSuccess(response.data));
// 		})
// 		.catch(err => dispatch(fetchFailure(err)));
// };
export const fetchCreatives = queryString => (dispatch, getState) => {
	const startIndex = getState().creativeReducer.creativesStartIndex;
	const limit = getState().creativeReducer.creativeRequestSize;

	if (startIndex === 0) dispatch(fetchRequest());
	const filters = extractFilters(queryString);

	dispatch(setSelectedFilters(filters));
	// const filterQuery = createFilterQuery(
	// 	getState().creativeReducer.selectedFilters,
	// 	getState().creativeReducer.searchInput,
	// );

	const specialtagsDetails = specialTags(getState().creativeReducer.selectedFilters);
	dispatch(setSpecialTags(specialtagsDetails));

	//specialTags(getState().creativeReducer.selectedFilters);

	// axios({
	// 	method: 'post',
	// 	url: `${process.env.REACT_APP_API_URL}/graphql`,
	// 	data: {
	// 		query: `
	// 				query{
	// 					creatives(start: ${startIndex},limit: ${limit}, sort: "createdAt:desc", ${filterQuery}){
	// 						id
	// 						title
	// 						thumbnail{
	// 							url
	// 						}
	// 						region{
	// 							name
	// 						}
	// 						slug
	// 						campaign_created_at
	// 						post_sales
	// 					}
	// 				}
	// 			`,
	// 	},
	// })
	// 	.then(response => {
	// 		if (response.data.data.creatives === null) {
	// 			const err = {};
	// 			err.createNotFound = true;
	// 			err.message = response.data.errors.message;
	// 			//return dispatch(fetchFailure(err));
	// 			return dispatch(fetchCreativesSuccess([]));
	// 		} else {
	// 			console.log(response.data.data.creatives);
	// 			return dispatch(fetchCreativesSuccess(response.data.data.creatives));
	// 		}
	// 	})
	// 	.catch(err => dispatch(fetchFailure(err)));
};
export const fetchFilterCreatives = queryString => (dispatch, getState) => {
	const startIndex = getState().creativeReducer.creativesStartIndex;
	const limit = getState().creativeReducer.creativeRequestSize;
	//console.log('State', getState().creativeReducer);
	if (startIndex === 0) dispatch(fetchRequest());
	const filters = extractFilters(queryString);

	dispatch(setSelectedFilters(filters));
	// const filterQuery = createFilterQuery(
	// 	getState().creativeReducer.selectedFilters,
	// 	getState().creativeReducer.searchInput,
	// );

	const specialtagsDetails = specialTags(getState().creativeReducer.selectedFilters);
	dispatch(setSpecialTags(specialtagsDetails));
	//console.log(filters);

	const y = fetchFiltersDetails(filters, getState().creativeReducer.backupCreatives);

	//console.log('indexing-->', startIndex, limit, 'creatives', y.length);
	const modifiedData = pagination(y, startIndex, limit);
	//console.log(modifiedData);
	//specialTags(getState().creativeReducer.selectedFilters);
	dispatch(creativeCount(y.length));
	return dispatch(fetchCreativesSuccess(modifiedData));
};

export const fetchFilters = () => dispatch => {
	dispatch(fetchRequest());
	axios({
		method: 'post',
		url: `${process.env.REACT_APP_API_URL}/graphql`,
		data: {
			query: `
				query{
					brands{
						name
					}

					countries{
						name
					}
					formats{
						name
					}
					functionalities{
						name
					}
					verticals{
						name
					}
				} 
			`,
		},
	})
		.then(response => {
			return dispatch(fetchFiltersSuccess(response.data.data));
		})
		.catch(err => dispatch(fetchFailure(err)));
};

export const fetchCreative = slug => dispatch => {
	dispatch(fetchRequest());
	let headers = {};
	const jwt = localStorage.getItem('jwt');
	if (jwt)
		headers = {
			Authorization: `Bearer ${jwt}`,
		};
	axios({
		method: 'post',
		url: `${process.env.REACT_APP_API_URL}/graphql`,
		data: {
			query: `
				query{
					creatives(where: {slug: "${slug}"}){
						id
						createdAt
						created_by{
							username
						}
						title
						slug
						description
						thumbnail{
							url
						}
						preview_link
						carousel{
							url
						}
						video{
							url
							height
    						width
						}
						complexity
						post_sales
						salesforce_ticket_link
						attachment{
							url
						}
						region{
							id
							name
						}
						country{
							id
							name
						}
						brand{
							id
							name
						}
						subbrand{
							id
							name
						}
						specialtags{
							id
							name
						}
						vertical{
							id
							name
						}
						designers{
							id
							name
						}
						osplatforms{
							id
							name
						}
						formats{
							id
							name
						}
						functionalities{
							id
							name
						}
						campaign_created_at
						ppt_pdf_link
					}
				}
			`,
		},
		headers,
	})
		.then(response => {
			return response.data.data.creatives && response.data.data.creatives.length
				? dispatch(fetchCreativeSuccess(response.data.data.creatives[0]))
				: dispatch(authorizationFailure());
		})
		.catch(err => dispatch(fetchFailure(err)));
};
export const fetchAllCreatives = () => (dispatch, getState) => {
	//dispatch(fetchRequest());
	//console.log("State", getState().demoCreativeReducer)
	axios({
		method: 'post',
		url: `${process.env.REACT_APP_API_URL}/graphql`,
		data: {
			query: `
				query{
					creatives(start: 0, sort: "createdAt:desc")
					{
						id
						title
						thumbnail{
							url
						}
						region{
							name
						}
						slug
						campaign_created_at
						post_sales,
						formats{
				 			 name
						},
						vertical{
						name
						},
						brand{
						name
						},
						subbrand{
						name
						},
						specialtags{
						name
						},
						country{
						name
						},
						functionalities{
							name
						}
				
				
					}
				
				} 
			`,
		},
	})
		.then(response => {
			const details = response.data.data.creatives;
			return dispatch(fetchAllCreativeSuccess(details));
		})
		.catch(err => dispatch(fetchFailure(err)));
};

export const fetchAllCreativesFromHome = () => (dispatch, getState) => {
	axios({
		method: 'post',
		url: `${process.env.REACT_APP_API_URL}/graphql`,
		data: {
			query: `
				query{
					creatives(start: 0, sort: "createdAt:desc")
					{
						id
						title
						thumbnail{
							url
						}
						region{
							name
						}
						slug
						campaign_created_at
						post_sales,
						formats{
				 			 name
						},
						vertical{
						name
						},
						brand{
						name
						},
						subbrand{
						name
						},
						specialtags{
						name
						},
						country{
						name
						},
						functionalities{
							name
						}
				
				
					}
				
				} 
			`,
		},
	})
		.then(response => {
			const details = response.data.data.creatives;
			return dispatch(fetchAllCreativeFromHomeSuccess(details));
		})
		.catch(err => dispatch(fetchFailure(err)));
};

export const openDialogModal = creative => dispatch => {
	const slug = creative.slug;
	dispatch(fetchRequest());
	let headers = {};
	const jwt = localStorage.getItem('jwt');
	if (jwt)
		headers = {
			Authorization: `Bearer ${jwt}`,
		};
	axios({
		method: 'post',
		url: `${process.env.REACT_APP_API_URL}/graphql`,
		data: {
			query: `
				query{
					creatives(where: {slug: "${slug}"}){
						id
						createdAt
						created_by{
							username
						}
						title
						slug
						description
						thumbnail{
							url
						}
						preview_link
						carousel{
							url
						}
						video{
							url
							height
    						width
						}
						complexity
						post_sales
						salesforce_ticket_link
						attachment{
							url
						}
						region{
							id
							name
						}
						country{
							id
							name
						}
						brand{
							id
							name
						}
						subbrand{
							id
							name
						}
						specialtags{
							id
							name
						}
						vertical{
							id
							name
						}
						designers{
							id
							name
						}
						osplatforms{
							id
							name
						}
						formats{
							id
							name
						}
						functionalities{
							id
							name
						}
						campaign_created_at
						ppt_pdf_link
					}
				}
			`,
		},
		headers,
	})
		.then(response => {
			//console.log(response);
			if (response.data.data.creatives === null && response.data.errors.length > 0) {
				const err = {};
				err.createNotFound = true;
				err.message = "Creative Doesn't Exist";
				return dispatch(fetchFailure(err));
			} else if (response.data.data.creatives.length === 0) {
				//console.log('inside auth failure');
				dispatch(openModal());
				return dispatch(authorizationFailure());
			} else {
				dispatch(openModal());
				dispatch(fetchCreativeSuccess(response.data.data.creatives[0]));
			}
		})
		.catch(err => dispatch(fetchFailure(err)));
};
export const closeDialogModal = () => dispatch => {
	dispatch(closeModal());
};
export const readMore = () => dispatch => {
	dispatch(showMore());
};
