import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Slide from '@material-ui/core/Slide';
// import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { Link } from '@reach/router';
import IconButton from '@material-ui/core/IconButton';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppDrawer from './AppDrawer';
import logo from '../assets/images/creative-labs-logo.png';
import { fireEvent } from '../utils/tracking';
import Button from '@material-ui/core/Button';
import './Header.scss';
import { useLocation } from '@reach/router';
import { connect } from 'react-redux';
// const HideOnScroll = ({ children }) => {
// 	const trigger = useScrollTrigger();
// 	return (
// 		<Slide appear={false} direction="down" in={!trigger}>
// 			{children}
// 		</Slide>
// 	);
// };

const menuItems = [
	{
		name: 'GALLERY',
		link: '/gallery?postsales',
		icon: 'gallery',
	},
	{
		name: 'FEATURES',
		link: '/functionalities',
		icon: 'Functionalities',
	},
	{
		name: 'VERTICALS',
		link: '/verticals',
		icon: 'Ad Verticals',
	},
];

const Header = ({ button }) => {
	const location = useLocation();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const toggleDrawer = open => {
		setDrawerOpen(open);
	};
	const userLogin = () => {
		localStorage.setItem('ru', location.pathname);
		window.location.href = `${process.env.REACT_APP_API_URL}/connect/microsoft`;
	};
	const userLogout = () => {
		localStorage.removeItem('jwt');
		localStorage.removeItem('email');
		window.location.reload();
	};
	const isLoggedInn = localStorage.getItem('jwt');
	const email = localStorage.getItem('email');

	return (
		<>
			{/* <HideOnScroll {...props}> */}
			<AppBar className="header">
				<Toolbar className="toolbar">
					<div className="headerContent">
						{button === 'back' ? (
							<IconButton
								className="backBtn"
								onClick={() => {
									fireEvent('Header', 'Click', `Back Button Clicked`);
									window.history.back();
								}}
							>
								<ArrowBackIcon className="backIcon" />
							</IconButton>
						) : button === 'menu' && menuItems.length ? (
							<div className="menuBtnCtn">
								<div className="menuHexagon">&#x2B22;</div>
								<IconButton
									onClick={() => {
										fireEvent('Header', 'Click', `Menu Button Clicked`);
										toggleDrawer(true);
									}}
									className="menuBtn"
								>
									<MenuRoundedIcon className="menuIcon" />
								</IconButton>
							</div>
						) : null}
						<div className="headerLogoCtn">
							<Link
								to="/"
								className="headerLogo"
								onClick={() => fireEvent('Header', 'Click', `Logo Clicked`)}
							>
								<img src={logo} alt="ThinkLoud Logo" />
							</Link>
							{/* <div className="headerSubText">We add more color to your campaigns</div> */}
						</div>
						<div className="headerBtnCtn">
							{menuItems.map(item => (
								<>
									{/* <Link
										to={item.link}
										className="headerBtn"
										key={item.name}
										onClick={() => fireEvent('Header', 'Click', `${item.name} Clicked`)}
									>
										{item.name}
									</Link> */}
									<Button component={Link} to={item.link} color="primary" className="headerBtn">
										{item.name}
									</Button>
									<div className="separator">|</div>
								</>
							))}

							{/* <Link to="/gallery" className="headerBtn galleryLink">
								Gallery
							</Link> */}

							{!isLoggedInn ? (
								<>
									<Button color="primary" onClick={() => userLogin()} className="headerBtn">
										LOGIN
									</Button>
								</>
							) : (
								<Button color="primary" onClick={() => userLogout()} className="headerBtn">
									LOGOUT
								</Button>
							)}
						</div>
					</div>
				</Toolbar>
			</AppBar>
			{/* </HideOnScroll> */}

			<AppDrawer
				drawerOpen={drawerOpen}
				toggleDrawer={toggleDrawer}
				menuItems={menuItems}
				isLoggedInn={isLoggedInn}
			/>
		</>
	);
};

export default Header;
