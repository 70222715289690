import axios from 'axios';
import {
	FETCH_REQUEST,
	FETCH_FAILURE,
	FETCH_VERTICALS_SUCCESS,
	FETCH_VERTICAL_SUCCESS,
	SELECT_VERTICAL,
	HIDE_LOADER,
} from './VerticalActionTypes';

export const fetchRequest = () => {
	return {
		type: FETCH_REQUEST,
	};
};
export const fetchVerticalSuccess = vertical => {
	return {
		type: FETCH_VERTICAL_SUCCESS,
		payload: vertical,
	};
};
export const fetchVerticalsListSuccess = verticals => {
	return {
		type: FETCH_VERTICALS_SUCCESS,
		payload: verticals,
	};
};
export const fetchFailure = error => {
	return {
		type: FETCH_FAILURE,
		payload: error,
	};
};
export const fetchVertical = slug => dispatch => {
	dispatch(fetchRequest());

	axios({
		method: 'post',
		url: `${process.env.REACT_APP_API_URL}/graphql`,
		data: {
			query: `query{
					verticals(where:{slug:"${slug}"}){
						name,
						video{
						url
						}
						icon{
							url
  						},
						description,
						slug,
						featured(limit:5){
							title,
							preview_link,
							slug,
							thumbnail{
								url
							},
							campaign_created_at,
							region{
								name
							}
						},
						creatives(limit:5,sort: "createdAt:desc",where:{post_sales:true}){
							title,
							preview_link,
							slug,
							thumbnail{
								url
							},
							campaign_created_at,
							region{
								name
							}
						  }
					  }
					}`,
		},
	})
		.then(response => {
			dispatch(fetchVerticalSuccess(response.data.data.verticals[0]));
		})
		.catch(err => dispatch(fetchFailure(err)));
};
export const fetchVerticalList = () => dispatch => {
	dispatch(fetchRequest());
	axios({
		method: 'post',
		url: `${process.env.REACT_APP_API_URL}/graphql`,
		data: {
			query: `query{
				verticals(where:{published:true}){
					id,
							name,
							icon{
							  url
							},
							description,
							
							slug
						  }
			  }`,
		},
	})
		.then(response => {
			dispatch(fetchVerticalsListSuccess(response.data.data.verticals));
		})
		.catch(err => dispatch(fetchFailure(err)));
};
export const hideLoader = () => dispatch => {
	dispatch(hideLoader1());
};
export const hideLoader1 = () => {
	return {
		type: HIDE_LOADER,
	};
};
