import { checkElementInArray } from './array';

export const allowedFilterTypes = [
	'brands',
	'countries',
	'designers',
	'formats',
	'functionalities',
	'osplatforms',
	'regions',
	'specialtags',
	'subbrands',
	'verticals',
	'postsales',
];

export const createFilterQuery = (selectedFilters = {}, searchInput = '') => {
	const filterQuery = {};
	let filterName = '';
	//console.log('creativeFilterQuery', selectedFilters, searchInput);
	Object.keys(selectedFilters).forEach(selectedFilterType => {
		if (selectedFilters[selectedFilterType].length) {
			switch (selectedFilterType) {
				case 'brands':
					filterName = 'brand';
					break;
				case 'countries':
					filterName = 'country';
					break;
				case 'designers':
					filterName = 'designers';
					break;
				case 'formats':
					filterName = 'formats';
					break;
				case 'functionalities':
					filterName = 'functionalities';
					break;
				case 'osplatforms':
					filterName = 'osplatforms';
					break;
				case 'regions':
					filterName = 'region';
					break;
				case 'specialtags':
					filterName = 'specialtags';
					break;
				case 'subbrands':
					filterName = 'subbrand';
					break;
				case 'verticals':
					filterName = 'vertical';
					break;
				case 'postsales':
					filterName = 'post_sales';
					break;
				default:
					return;
			}
			if (filterName === 'post_sales') {
				filterQuery['post_sales_in'] = ['true'];
			} else {
				filterQuery[filterName] = { name_in: {} };
				filterQuery[filterName]['name_in'] = selectedFilters[selectedFilterType];
			}
		}
	});
	//const searchQuery = `_or: [{title_contains: "${searchInput}"}, {functionalities: {name_contains: "${searchInput}"}}, {region: {name_contains: "${searchInput}"}}, {country: {name_contains: "${searchInput}"}}, {brand: {name_contains: "${searchInput}"}}, {subbrand: {name_contains: "${searchInput}"}}, {specialtags: {name_contains: "${searchInput}"}}, {vertical: {name_contains: "${searchInput}"}}, {osplatforms: {name_contains: "${searchInput}"}}, {formats: {name_contains: "${searchInput}"}}, {designers: {name_contains: "${searchInput}"}}]`;
	const searchQuery = `_or: [{title_contains: "${searchInput}"}, {functionalities: {name_contains: "${searchInput}"}}, {brand: {name_contains: "${searchInput}"}}, {subbrand: {name_contains: "${searchInput}"}}, {specialtags: {name_contains: "${searchInput}"}}, {vertical: {name_contains: "${searchInput}"}}]`;
	//console.log('stringify', JSON.stringify(filterQuery));
	//	console.log('parse', JSON.parse(filterQuery));

	return Object.keys(filterQuery).length
		? searchInput
			? `where: {${searchQuery}, ${JSON.stringify(filterQuery)
					.replaceAll('{"', '{')
					.replaceAll('":', ':')
					.replaceAll('},"', '},')
					.replaceAll('],"', '],')
					.substr(1)}`
			: `where: ${JSON.stringify(filterQuery)
					.replaceAll('{"', '{')
					.replaceAll('":', ':')
					.replaceAll('},"', '},')
					.replaceAll('],"', '],')}`
		: searchInput
		? `where: {${searchQuery}}`
		: ``;
};

export const extractFilters = (queryString = '') => {
	const filters = {};
	let searchInput = '';
	if (queryString === '') return { filters, searchInput };
	const decodedQueryString = decodeURIComponent(queryString.substr(1));
	const queryParams = decodedQueryString.split('&');
	for (let i = 0; i < queryParams.length; i++) {
		const param = queryParams[i];
		if (!param.includes('=') & !param.includes('postsales')) {
			queryParams[i - 1] = `${queryParams[i - 1]}&${queryParams[i]}`;
			queryParams.splice(i, 1);
		}
	}

	queryParams.forEach(param => {
		const [key, value] = param.split('=');
		if (checkElementInArray(key, allowedFilterTypes))
			filters[key] = filters[key] && filters[key].length ? [...filters[key], value] : [value];
		else if (key === 'search') searchInput = value;
	});

	return {
		filters,
		searchInput,
	};
};
export const removeQueryStringParameter = (uri, key, value) => {
	const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
	const separator = uri.indexOf('?') !== -1 ? '&' : '?';

	if (uri.match(re)) {
		return uri.replace(re, '');
	}
};
export const doesFilterExist = (queryString = '') => {
	const filters = {};
	let doesFilterExist = false;
	let searchInput = '';
	if (queryString === '') return { doesFilterExist };
	const decodedQueryString = decodeURIComponent(queryString.substr(1));
	const queryParams = decodedQueryString.split('&');
	queryParams.forEach(param => {
		const [key, value] = param.split('=');
		if (checkElementInArray(key, allowedFilterTypes)) {
			filters[key] = filters[key] && filters[key].length ? [...filters[key], value] : [value];
			doesFilterExist = true;
		} else if (key === 'search') {
			searchInput = value;
			doesFilterExist = true;
		}
	});

	return {
		doesFilterExist,
	};
};
export const filterData = (filterObject, creatives) => {
	const filterKeys = Object.keys(filterObject).filter(key => filterObject[key].length !== 0);
	let responseArray = [...creatives];

	const arrayMatch = (array, values) => {
		if (!values.length) return true;
		if (!array.length) return false;
		const arrayValues = array.map(item => item.name);
		return values.some(value => arrayValues.includes(value));
	};

	const objMatch = (obj, values) => {
		if (!values.length) return true;
		if (!obj) return false;
		const objValues = Object.values(obj);
		return values.some(value => objValues.includes(value));
	};

	const stringMatch = (str1, str2) => {
		if (!str2.length) return false;
		return str1.toLowerCase().includes(str2.toLowerCase());
	};

	const onSearch = (keyword, creative) => {
		let result = false;
		const referenceArray = [
			'brand',
			'country',
			'formats',
			'functionalities',
			'title',
			'vertical',
			'slug',
			'specialtags',
		];
		result = referenceArray.some(key => {
			if (creative[key]) {
				if (typeof creative[key] === 'string') {
					return stringMatch(creative[key], keyword);
				} else {
					if (Array.isArray(creative[key])) {
						const searchFlag = creative[key].some(value => {
							return stringMatch(value.name, keyword);
						});
						return searchFlag;
					} else {
						const arrayValues = Object.values(creative[key]);
						const searchFlag = arrayValues.some(value => {
							return stringMatch(value, keyword);
						});
						return searchFlag;
					}
				}
			} else return false;
		});

		return result;
	};

	for (let i = 0; i < filterKeys.length; i++) {
		const key = filterKeys[i];
		responseArray = responseArray.filter(creative => {
			if (key === 'title') {
				// return stringMatch(creative[key], filterObject[key]);
				const searchFlag = onSearch(filterObject[key], creative);
				return searchFlag;
			}
			return Array.isArray(creative[key])
				? arrayMatch(creative[key], filterObject[key])
				: objMatch(creative[key], filterObject[key]);
		});
	}

	return responseArray;
};
export const fetchFiltersDetails = (filters, data) => {
	//console.log(filters);

	let functionalities = [];
	if (filters.filters.functionalities) {
		functionalities = filters.filters.functionalities;
	}

	let title = '';
	if (filters.searchInput != '') {
		title = filters.searchInput;
	}

	let formats = [];
	if (filters.filters.formats) {
		formats = filters.filters.formats;
	}

	let countries = [];
	if (filters.filters.countries) {
		countries = filters.filters.countries;
	}

	let brands = [];
	if (filters.filters.brands) {
		brands = filters.filters.brands;
	}

	let verticals = [];
	if (filters.filters.verticals) {
		verticals = filters.filters.verticals;
	}
	let specialTags = [];
	if (filters.filters.specialtags) {
		specialTags = filters.filters.specialtags;
	}
	let post_sales = false;
	if (filters.filters.postsales) {
		post_sales = true;
	}

	const filterString = {
		title: title,
		formats: formats, //[]
		vertical: verticals, //{}
		brand: brands, //{}
		country: countries, //{}
		functionalities: functionalities,
		specialtags: specialTags,
	};
	if (post_sales == true) {
		const fetchedData = filterData(filterString, data);
		const newFetchedData = fetchedData.filter(o => o.post_sales == true);
		return newFetchedData;
	}
	const fetchedData = filterData(filterString, data);
	return fetchedData;
};
export const pagination = (items, startIndex, per_page) => {
	// var page = page || 1,
	// 	per_page = per_page || 10,
	// 	offset = (page - 1) * per_page,

	// 	paginatedItems = items.slice(offset).slice(page, per_page),
	// 	//total_pages = Math.ceil(items.length / per_page);
	// return {

	// 	per_page: per_page,
	// 	pre_page: page - 1 ? page - 1 : null,
	// 	next_page: (total_pages > page) ? page + 1 : null,
	// 	total: items.length,
	// 	data: paginatedItems
	// };
	const end = startIndex + per_page;
	const y = items.slice(startIndex, end);
	return y;
};
export const specialTags = (selectedFilters = {}) => {
	let specialTags = '';
	Object.keys(selectedFilters).forEach(selectedFilter => {
		if (selectedFilters[selectedFilter].length) {
			if (selectedFilter == 'specialtags') {
				specialTags = selectedFilters[selectedFilter];
			}
		}
	});

	return specialTags;
};
