import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Router, globalHistory } from '@reach/router';
import { Provider } from 'react-redux';
// import ScrollToTop from './ScrollToTop';
import Home from '../pages/Home';
// import CreativesOld from '../pages/CreativesOld';
import CreativeDetail from '../pages/CreativeDetail';
import FunctionalitiesList from '../pages/Functionalities';
import Faqs from '../pages/Faqs';
import LoginRedirect from '../pages/LoginRedirect';
import NotFound from '../pages/NotFound';
import Footer from './Footer';
import { pageView } from '../utils/tracking';
import AdFormatDetails from '../pages/AdFormatDetails';
import Creatives from '../pages/Creatives';
import VerticalDetails from '../pages/VerticalDetails';
import VerticalsPage from '../pages/Verticals';
const AppRouter = props => {
	useEffect(() => {
		const unsubscribeHistory = globalHistory.listen(({ location }) => {
			pageView();
		});
		return () => unsubscribeHistory();
	}, []);

	return (
		<Provider store={props.store}>
			<Router primary={false}>
				{/* <ScrollToTop path="/"> */}
				<Home path="/" />
				{/* <Creatives path="/creative" /> */}

				<CreativeDetail path="/creative/:creativeId" />
				<FunctionalitiesList path="/functionalities" />
				<Creatives path="/gallery" />
				{/* <FunctionalityDetail path="/docs/:functionalityId" /> */}
				<Faqs path="/faqs" />
				<LoginRedirect path="/connect/microsoft/redirect" />
				<AdFormatDetails path="/functionality/:functionalityId" />
				<NotFound default />

				<VerticalDetails path="/vertical/:verticalId" />
				<VerticalsPage path="/verticals" />
				{/* </ScrollToTop> */}
			</Router>
			<Footer />
		</Provider>
	);
};

AppRouter.propTypes = {
	store: PropTypes.object.isRequired,
};

export default AppRouter;
